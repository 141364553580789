import { lazy } from 'react';


const Login = lazy(() => import('./Containers/Login'));
//const CreateUser = lazy(() => import('./Containers/User/createUser'));
const getComponent=(pathimported)=>{    
  return lazy(() =>  pathimported );
}
const Components =[
   { path: '/', component: getComponent(import('./Containers/Dashboard'))  },  
   { path: '/dashboard', component: getComponent(import('./Containers/Dashboard')) },
   { path: '/reports/conversion', component: getComponent(import('./Containers/Reports/Conversion'))},
   { path: '/reports/conversion-funnel', component: getComponent(import('./Containers/Reports/ConversionFunnel'))},
   { path: '/reports/product-sale', component: getComponent(import('./Containers/Reports/ProductSale'))},
   { path: '/reports/product-click', component: getComponent(import('./Containers/Reports/ProductClick'))},
   { path: '/reports/training-result', component: getComponent(import('./Containers/Reports/TrainingResult'))},
   { path: '/profile',component: getComponent(import('./Containers/Account/Details'))},
   { path: '/profile/manage',component: getComponent(import('./Containers/Account/Manage'))}
   ]
const logined_user = JSON.parse(localStorage.getItem('loginedUser'));
if(logined_user && logined_user.accountType && logined_user.accountType !== 'merchant'){
  Components.push( { path: '/accounts', component: getComponent(import('./Containers/Account/List'))})
  Components.push( { path: '/account-details/:id', component: getComponent(import('./Containers/Account/Details'))})
  if(logined_user.isSuperAdmin){
  Components.push( { path: '/account/manage', component: getComponent(import('./Containers/Account/Manage'))} )
  }
}
export {
  Components,
  Login,  
};
