import axios from 'axios';
import config from './config';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory({ forceRefresh: true });

//Funcion for setting Header request information
function headerInfo() {
  const header = {
    'Content-Type': 'application/json',
  };
  if (localStorage.getItem('token')) {
    header.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  return header;
}

//Function for posting data to API
//Arguments : url - the part of api eg: 'user/v1/admin/login'
//            param - post data (body)
function postApi({ url, param }) {
  return axios.post(`${config.API_DOMAIN}${url}`, param, {
    headers: headerInfo(),
  });
}

//Function for listing data from API
//Arguments:
// url : the part of api eg: 'user/v1/admin'
// param: the query strings such as paging, sorting, searching etc;
// Based on the the query string manipulate the API's query strings
function listApi({ url, param }) {  
  // const { page = 1, perPage = 10, id } = param;
  const { page = 1, perPage = 10, id, filter, name, sort } = param;
  let apiUrl = `${config.API_DOMAIN}${url}`;
  if (id) {
    apiUrl += '/' + id;
  }

  let queryString = '';
  Object.keys(param).map((itm) => {
    if (
      itm !== 'page' &&
      itm !== 'perPage' &&
      itm !== 'id' &&
      itm !== 'filter'
    ) {
      queryString += `&${itm}=${param[itm]}`;
    }
    if(itm=="filter" && param[itm]){
      queryString += `&_filters=${param[itm]}`;
    }
    return itm;
  });
  apiUrl += `?page=${page}&perPage=${perPage}${queryString}`;
  return axios.get(apiUrl, {
    headers: headerInfo(),
  }).then(function(res){
    return res;
  }).catch(function(ex){
    redirectOnAuthError(ex.response);      
    return ex;    
  });
}

function putApi({ url, param }) {
  return axios.put(`${config.API_DOMAIN}${url}`, param, {
    headers: headerInfo(),
  }).then(function(res){
    return res;
  }).catch(function(ex){
    redirectOnAuthError(ex.response);      
    return ex;    
  });
}

function deleteApi({ url, param }) {
  return axios.delete(`${config.API_DOMAIN}${url}`, {
    data: param,
    headers: headerInfo(),
  }).then(function(res){
    return res;
  }).catch(function(ex){
    redirectOnAuthError(ex.response);      
    return ex;    
  });
}

function detailsApi({url,param}){
  return axios.get(`${config.API_DOMAIN}${url}${param.id}`, {
    data: param,
    headers: headerInfo(),
  }).then(function(res){
    return res;
  }).catch(function(ex){
    redirectOnAuthError(ex.response);      
    return ex;    
  });
}
function redirectOnAuthError(response) {
  const { data } = response;
  if(!data.success && data.message_key && (data.message_key === 'token_required' || data.message_key === 'token_invalid') )
  {
    localStorage.clear();
    history.push('/');
  }
}
//Exporting includes Config 
//So can use config values such as api url keys
//Need to include detail, update and delete API keys
export { postApi, listApi, putApi, deleteApi, detailsApi, redirectOnAuthError, config };
