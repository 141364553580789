import React from 'react';
import { withRouter } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Button, MenuItem, Select } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssessmentIcon from '@material-ui/icons/Assessment';
import BarChartIcon from '@material-ui/icons/BarChart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { merchantListApi } from '../../api/account';

import './menu.scss';
import ThemeContent from '../../ThemeContent';
import MenuContext from '../../MenuContext';

const history = createBrowserHistory({ forceRefresh: true });

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      logined_user: JSON.parse(localStorage.getItem('loginedUser')),
      merchant_key: '',
      merchantList: [],
    };
  }
  componentDidMount() {
    this.loadMerchant();
  }
  loadMerchant = async () => {
    await Promise.all([
      this.state.logined_user.accountType === 'admin'
        ? merchantListApi()
        : null,
    ]).then((response) => {
      const [result] = response;
      if (result) {
        const { data: merchantList } = result;
        let [first] = merchantList;
        const selectedMerchant = localStorage.getItem('merchant_key');
        if (selectedMerchant) {
          [first] = merchantList.filter(
            (itm) => itm.clientKey === selectedMerchant
          );
        }
        if (first) {
          const {
            clientKey: merchant_key,
            name: merchant_name,
            currency,
          } = first;
          localStorage.setItem('merchant_key', merchant_key);
          localStorage.setItem('merchant_currency', currency);
          this.setState({ merchantList, merchant_key, merchant_name });
        }
      }
    });
  };
  handleClick = (event, cur) => {
    this.setState({ [event]: !cur });
  };
  logout = () => {
    localStorage.clear();
    history.push('/');
  };
  handleChange = (event) => {
    const merchant_key = event.target.value;
    const [merchant] = this.state.merchantList.filter(
      (itm) => itm.clientKey === merchant_key
    );
    const { name: merchant_name, currency } = merchant;
    this.setState({ merchant_key, merchant_name });
    localStorage.setItem('merchant_currency', currency);
  };
  render() {
    const {
      location: { pathname, pathInLower = pathname.toLowerCase() },
    } = this.props;
    return (
      <div className="HeaderNavigation" style={this.props.styles}>
        <div className="container">
          <div className="row">
            <ThemeContent.Consumer>
              {([merchant, setMerchant]) => (
                <MenuContext.Consumer>
                  {([menuDrop, setmenuDrop]) => (
                    <ul
                      className={`navigation ${
                        menuDrop ? 'inactive' : 'active'
                      }`}
                    >
                      {this.state.logined_user.accountType !== 'merchant' && (
                        <li>
                          <ThemeContent.Consumer>
                            {([merchant, setMerchant]) => (
                              <Select
                                displayEmpty
                                inputProps={{
                                  'aria-label': 'Without label',
                                  style: { width: 100 },
                                }}
                                value={this.state.merchant_key}
                                title={this.state.merchant_name}
                                onChange={(e) => {
                                  this.handleChange(e);
                                  localStorage.setItem(
                                    'merchant_key',
                                    e.target.value
                                  );
                                  setMerchant(e.target.value);
                                }}
                                className="navigation-dropdown"
                              >
                                {this.state.merchantList.length ? (
                                  this.state.merchantList.map((option) => (
                                    <MenuItem
                                      key={option.clientKey}
                                      value={option.clientKey || ''}
                                    >
                                      {option.name || ''}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem value="">Merchants</MenuItem>
                                )}
                              </Select>
                            )}
                          </ThemeContent.Consumer>
                        </li>
                      )}
                      <li>
                        <NavLink to="/dashboard">
                          {/* <DashboardIcon className="navigation__icon" />  */}
                          <i className="icon-dashboard navigation__icon"></i>{' '}
                          Dashboard
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/reports/conversion">
                          {/* <AssessmentIcon className="navigation__icon" />  */}
                          <i className="icon-conversion-report navigation__icon"></i>{' '}
                          Conversion Reports
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/reports/conversion-funnel">
                          {/* <BarChartIcon className="navigation__icon" />  */}
                          <i className="icon-conversion-funnel navigation__icon"></i>{' '}
                          Conversion Funnel
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/reports/product-sale">
                          {/* <ListAltIcon className="navigation__icon" />  */}
                          <i className="icon-product-report navigation__icon"></i>{' '}
                          Product Report
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/reports/training-result">
                          {/* <TrendingUpIcon className="navigation__icon" />  */}
                          <i className="icon-training-report navigation__icon"></i>{' '}
                          Training Reports
                        </NavLink>
                      </li>
                      {this.state.logined_user.accountType !== 'merchant' && (
                        <li>
                          <NavLink to="/accounts">
                            {/* <AssignmentIndIcon className="navigation__icon" /> */}
                            <i className="icon-manage-account navigation__icon"></i>{' '}
                            Manage Accounts
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  )}
                </MenuContext.Consumer>
              )}
            </ThemeContent.Consumer>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Menu);
