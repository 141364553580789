import React, { useState } from 'react';
import './App.css';
import Routes from './Routes';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import ThemeContext from './ThemeContent';
import MenuContext from './MenuContext';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#34cc67',
      dark: '#2cae57',
      contrastText: '#ffffff',
    },
  },
});

function App() {
  const _theme = useState('mercy');
  const _menu = useState(true);
  return (
    <div className="App">
      <ThemeContext.Provider value={_theme}>
        <MenuContext.Provider value={_menu}>
          <ThemeProvider theme={theme}>
            <Routes />
            <ToastContainer />
          </ThemeProvider>
        </MenuContext.Provider>
      </ThemeContext.Provider>
    </div>
  );
}

export default App;
