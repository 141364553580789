import jwt from 'jsonwebtoken';

const returnData=(isAuth,history,renderComponent) =>{
    if(isAuth)
    { 
        if(!localStorage.getItem('token')){ return null; }
        return localStorage.getItem('token');
    }
    if(!localStorage.getItem('token')){   history.push('/'); }
    else{  return renderComponent;}
}

const tokenExpiry = (params) => {
    const { isAuth=false,component:renderComponent=null,history=null } = params;    
    if(!localStorage.getItem('token')){
        return  returnData(isAuth,history,renderComponent);
    }
    //verify token expired
   const decryptToken = jwt.decode(localStorage.getItem('token'),{complete:true});   
   const currentDate = new Date();   
   if(decryptToken.payload.exp < currentDate.getTime()/1000){  
     localStorage.clear();    
   }   
   return  returnData(isAuth,history,renderComponent);
}

export default tokenExpiry;