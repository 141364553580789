export default {
    "account" : "account/v1/",
    "merchants": "account/v1/merchants",
    "accountAuth" : "account/v1/auth",
    "accountStatusChange": "account/v1/change-status",   
    "conversionReport": "reports/v1/conversion",
    "conversionFunnelReport": "reports/v1/conversion-funnel",
    "productSaleReport": "reports/v1/product-sale",
    "productClickReport": "reports/v1/product-click",
    "trainingResult": "reports/v1/training-result",
    "common":"common/v1/"
}