import React from 'react';
import Header from '../Header';
import Menu from '../Menu';

class Layout extends React.Component {
  render() {
    const styles = {     
      menu: {
        width: '100%',
        background: '#ffffff',
        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.08)',      
      }     
    };
    return (
      <div>
        <Header />
        <div>
          <Menu styles={styles.menu} />
          <div className="container">
            <div>{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Layout;
