import { listApi, putApi, deleteApi, postApi, detailsApi, config} from "../crud";

const accountListApi = (page, perPage,filter) => {  
    const param = {page, perPage,filter};
    return listApi({url:config.API_URL_KEYS.account,param})
      .then(response => {
        const { headers:{ "x-total-count": total }, data } = response;
        return { data, total };      
      });
  }; 

const merchantListApi = () => {
  const param = {};
  return listApi({url:config.API_URL_KEYS.merchants,param})
      .then(response => {
        const { headers:{ "x-total-count": total }, data } = response;
        return { data, total };      
      });
}

 const statusChangeApi = (id, status) => {  
    const param = {
        "ids" : [ id ],
        status
      }
    return putApi({url:config.API_URL_KEYS.accountStatusChange,param})  
      .then(response => {
        const { headers:{ "x-total-count": total }, data } = response;
        return { data, total };      
      });  
 }

const accountDeleteApi = (id) => {
    let param = {
      "ids" : ( Array.isArray(id) ? id :  [ id ] )
    }
    return deleteApi({url:config.API_URL_KEYS.account,param})  
      .then(response => {
        const { headers:{ "x-total-count": total }, data } = response;
        return { data, total };  
      });
  };

 const manageAccountApi= (data) => {
   if(data.id){
     return putApi({url:`${config.API_URL_KEYS.account}`,param:data});     
   }
   return postApi({url:config.API_URL_KEYS.account,param:data})
 } 

 const accountAuthApi = (data) => {   
    return postApi({url:config.API_URL_KEYS.accountAuth,param:data})
 }

 const accountDetailsApi = (id) => {
   console.log("Accounts");
   console.log(config.API_URL_KEYS.account)
   return detailsApi({url:config.API_URL_KEYS.account,param:{id}})
 }

 export { accountListApi, statusChangeApi, accountDeleteApi, manageAccountApi, accountAuthApi, accountDetailsApi, merchantListApi };