import React from 'react';
import { Link } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Logo from '../../assets/logo.png';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import './header.scss';
import MenuIcon from '@material-ui/icons/Menu';
import { Button } from '@material-ui/core';
import ThemeContent from '../../ThemeContent';
import MenuContext from '../../MenuContext';

const history = createBrowserHistory({ forceRefresh: true });

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = JSON.parse(localStorage.getItem('loginedUser'));
  }
  logout = () => {
    localStorage.clear();
    history.push('/');
  };
  render() {
    return (
      <header>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-8 d-flex align-items-center">
              <ThemeContent.Consumer>
                {([merchant, setMerchant]) => (
                  <MenuContext.Consumer>
                    {([menuDrop, setmenuDrop]) => (
                      <div
                        className="menu-toggle mr-sm-3 mr-2"
                        onClick={(e) => setmenuDrop(!menuDrop)}
                      >
                        <MenuIcon />
                      </div>
                    )}
                  </MenuContext.Consumer>
                )}
              </ThemeContent.Consumer>
              <Link to="/Dashboard">
                <img src={Logo} alt="Oorjit AI" style={{ float: 'left' }} />
              </Link>
            </div>
            <div
              className="col-4 align-items-center d-flex justify-content-end pl-sm-3 pl-0"
              style={{ textAlign: 'right' }}
            >
              {/* <Link
                to="/profile/manage"
                className="d-flex mr-sm-4 mr-2 setting__link"
              >
                <i className="icon-settings"></i>
              </Link> */}
              <div className="loggedUser">
                <div className="loggedUser__name-dp">
                  <span className="loggedUser__name">{this.state.name}</span>
                  <span className="loggedUser__dp">
                    {this.state.name.toUpperCase().charAt(0)}
                  </span>
                </div>
                <div className="loggedUser__dropdown">
                  <div className="loggedUser__dropdown-item">
                    <i className="icon-envelope loggedUser__icon"></i>
                    {this.state.email}
                  </div>
                  <Link to="/profile" className="loggedUser__dropdown-item">
                    <i className="icon-user loggedUser__icon"></i> View Profile
                  </Link>
                  <Link
                    to="/profile/manage"
                    className="loggedUser__dropdown-item"
                  >
                    <i className="icon-manage-account loggedUser__icon"></i>{' '}
                    Manage Profile
                  </Link>
                  <Link
                    to=""
                    onClick={this.logout}
                    className="loggedUser__dropdown-item"
                  >
                    <i className="icon-logout loggedUser__icon"></i> Logout
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
