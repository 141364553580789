import React, { Suspense, Fragment } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { tokenExpiry } from '../src/api/middleware';
import { Components, Login } from './routeComponent';
import { Redirect } from 'react-router-dom';
import Layout from './Components/Layout';
import LoadingImage from './assets/loader.svg';
const history = createBrowserHistory();
const auth = tokenExpiry({ isAuth: true });

class Routes extends React.Component {
  render() {
    return (
      <Router history={history}>
        {auth && (
          <Layout>
            <Suspense
              fallback={
                <div
                  style={{
                    position: 'fixed',
                    right: 0,
                    top: '82px',
                    left: '0%',
                    height: '100%',
                    display: 'table',
                    background: 'rgba(255, 255, 255, 0.5)',
                    zIndex: 100,
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'table-cell',
                      verticalAlign: 'middle',
                      fontSize: '19px',
                      color: ' #4161c9',
                    }}
                  >
                    <img
                      src={LoadingImage}
                      width="40"
                      height="40"
                      alt="Loading"
                      style={{ verticalAlign: 'middle' }}
                    />
                    Loading...
                  </div>
                </div>
              }
            >
              {Components.map((itm, i) => (
                <Route
                  exact
                  strict
                  path={itm.path}
                  component={tokenExpiry({ component: itm.component, history })}
                  key={i}
                />
              ))}
            </Suspense>
          </Layout>
        )}
        {!auth && (
          <Fragment>
            <Redirect to="/" />
            <Suspense
              fallback={
                <div
                  style={{
                    position: 'fixed',
                    right: 0,
                    top: '0',
                    left: 0,
                    height: '100%',
                    display: 'table',
                    background: 'rgba(255, 255, 255, 1)',
                    zIndex: 100,
                    width: '100%',
                    textAlign: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'table-cell',
                      verticalAlign: 'middle',
                      fontSize: '19px',
                      color: ' #4161c9',
                    }}
                  >
                    <img
                      src={LoadingImage}
                      width="40"
                      height="40"
                      alt="Loading"
                      style={{ verticalAlign: 'middle' }}
                    />
                    Loading...
                  </div>
                </div>
              }
            >
              {<Route exact path="/" component={Login} />}
            </Suspense>
          </Fragment>
        )}
      </Router>
    );
  }
}

export default Routes;
